// eslint-disable-next-line no-redeclare,no-unused-vars
function evtPosition(evt, el) {
  var left = evt.pageX + 10;
  if (evt.pageX + 20 + el.width() > $(window).width())
    left = evt.pageX - 20 - el.width();
  if (left < 0) left = 0;
  var top = evt.pageY + 10;
  if (evt.pageY + 20 + el.height() > $(window).height())
    top = evt.pageY - 20 - el.height();
  if (top < 0) top = 0;
  return {left: left + 'px', top: top + 'px'};
}

// TODO: unused, remove?
// eslint-disable-next-line no-redeclare,no-unused-vars
function fullscreen() {
  if (document.documentElement.requestFullScreen) {
    if (!document.fullScreen) document.documentElement.requestFullscreen();
    else document.exitFullScreen();
  } else if (document.documentElement.mozRequestFullScreen) {
    if (!document.mozFullScreen)
      document.documentElement.mozRequestFullScreen();
    else document.mozCancelFullScreen();
  } else if (document.documentElement.webkitRequestFullScreen) {
    if (!document.webkitIsFullScreen)
      document.documentElement.webkitRequestFullScreen();
    else document.webkitCancelFullScreen();
  }
}

// eslint-disable-next-line no-redeclare,no-unused-vars
function reload() {
  document.location.reload();
}

// eslint-disable-next-line no-redeclare,no-unused-vars
function clearCookies() {
  var cookies = Cookies.get();
  for (let key in cookies) Cookies.remove(key);
}
